<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>供应商比价</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}</div>
                        <div class="state">{{ detail.detail.state | state }}</div>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">委托项目：</span>
                    <span class="approval-detail-content">{{ detail.detail.payment }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.detail.remark }}</span>
                </div>
                <div class="approval-detail-title" v-if="detail.detail.state === 2">
                    <span class="approval-detail-tip red">驳回原因：</span>
                    <span class="approval-detail-content red">{{ detail.rejected_reason }}</span>
                </div>
                <!--                <div class="approval-detail-title">-->
                <!--                    <span class="approval-detail-tip">二维码：</span>-->
                <!--                    <div class="approval-detail-content">-->
                <!--                        <el-image :src="detail.detail.qr_code" style="width: 150px; height: 150px"></el-image>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <!--            <div v-if="detail.detail.sign" class="approval-detail-container">-->
            <!--                <div class="approval-list-tip">签字信息</div>-->
            <!--                <div class="approval-detail-title">-->
            <!--                    <span class="approval-detail-tip">签名：</span>-->
            <!--                    <div class="approval-detail-content">-->
            <!--                        <el-image :src="detail.detail.sign" style="width: 550px; height: auto"></el-image>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="sign-list">
                <el-table
                    :data="detail.enterprisePriceComparisonQuery"
                    header-row-class-name="table-header">
                    <el-table-column label="序号" width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.$index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="企业名称">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.enterprise_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="营业执照" width="200">
                        <template slot-scope="scope">
                            <div class="table-item">
                                <el-image :preview-src-list="[scope.row.businessLicense]" :src="scope.row.businessLicense"
                                          class="avatar" v-if="scope.row.businessLicense"></el-image>
                                <div v-else>--</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="报价单">
                        <template slot-scope="scope">
                            <div class="table-item">
                                <div class="table-item" v-for="item in scope.row.files " :key="item.id">
                                    <a class="file_link" :href="item.filePath | filePath" target="_blank"> {{ item.fileName }}</a>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" prop="operate" width="280">
                        <template slot-scope="scope">
                            <div class="table-item" v-if="detail.detail.state === 0 && member_type == 0">
                                <el-button class="margin-right-sm" type="primary" @click="cainaDialog(scope.row.id)">采纳
                                </el-button>
                                <!--                                <el-popconfirm-->
                                <!--                                    title="你确定要删除该报价吗？"-->
                                <!--                                    @confirm="delPriceComparison(scope.row.id)">-->
                                <!--                                    <el-button slot="reference" type="danger">删除</el-button>-->
                                <!--                                </el-popconfirm>-->
                            </div>
                            <div class="table-item" v-if="detail.detail.state !== 0">
                                <el-popconfirm
                                    v-if="scope.row.is_selected == 1"
                                    :title="scope.row.audit_opinion">
                                    <el-button slot="reference" type="primary">已采纳</el-button>
                                </el-popconfirm>
                                <el-button type="primary" v-else disabled>未采纳</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="approval-btn">
                <router-link :to="'/home/parity?id=' + pid" v-if="member_type == 1 && detail.detail.state !== 1">
                    <el-button class="approval-btn-right">修改
                    </el-button>
                </router-link>
                <el-button class="approval-btn-left"
                           v-if="member_type == 0 && detail.detail.state == 0"
                           @click="showDialog(2)">驳回
                </el-button>
                <el-popconfirm
                      title="你确定要删除该报价吗？"
                      @confirm="delEnterprisePriceComparison()"
                      v-if="member_type == 0">
                    <el-button class="approval-btn-left" slot="reference">删除
                    </el-button>
                  </el-popconfirm>

            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%">
            <span slot="title" class="dialog-title">备注</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    placeholder="请输入备注"
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
          </span>
        </el-dialog>
        <el-dialog
            :visible.sync="shenpi_rejectedVisible"
            width="30%"
            center
            :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批删除原因" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    type="textarea"
                    resize="none"
                    :rows="5"
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批删除原因' : '请输入审批拒绝原因'
          "
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="delEnterprisePriceComparison" :loading="requestState" :disabled="requestState" v-if="state === 1">确定</el-button>
                <el-button type="primary" @click="shenpi_rejected()" :loading="requestState" :disabled="requestState" v-else>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import config from "../../config/index";
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo
    },
    name: "payDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            detail: '',
            remark: "",
            cur_id: "",

        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        cainaDialog(id) {
            this.editVisible = true;
            this.cur_id = id
        },
        showDialog(type) {
            this.shenpi_rejectedVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.state = -1;
        },
        shenpi_rejected() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批删除原因" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 24, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 24, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        delPriceComparison(id) {
            this.$api.apiContent
                .delPriceComparison({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        delEnterprisePriceComparison() {
            this.$api.apiContent
                .delEnterprisePriceComparison({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.$router.go(-1)
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error("请输入备注");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .selectPriceComparison({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    enterprise_price_comparison_id: this.pid,
                    id: this.cur_id,
                    audit_opinion: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            enterprise_price_comparison_id: this.pid,
                            id: this.cur_id,
                            audit_opinion: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getParity({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    res.data.detail.qr_code = config.urlImgStr + res.data.detail.qr_code;
                    res.data.detail.sign = res.data.detail.sign ? config.urlImgStr + res.data.detail.sign : "";
                    this.detail = res.data
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "比价中";
                case 2:
                    return "已驳回";
                default:
                    return "已完成";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {},
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-confirm-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #333333;

        .dialog-tip {
            width: 120px;
            flex-shrink: 0;
        }

        .el-input,
        .el-date-editor--datetime,
        .el-date-editor,
        .el-radio-group,
        .el-textarea,
        .el-textarea__inner {
            flex-grow: 1;
            margin: 0 10px;
            font-size: 16px;
        }
    }

    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .approval-detail-file {
            flex: 7;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;

            a {
                font-size: 18px;
                cursor: pointer;
                margin-bottom: 8px;
                display: block;
                color: #3b77e7;

                &:hover {
                    font-size: 18px;
                }
            }
        }

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px 0;

            &.list-bottom {
                padding: 0 80px;
            }

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;

                &.list-bottom {
                    color: #ffffff;
                }
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;

                        .red {
                            color: red;
                        }
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }

    .sign-list {
        width: 100%;
        padding: 34px 28px;

        .table-item {
            text-align: center;
            font-size: 16px;
            color: #666666;

            .image-sign {
                width: 150px;
            }
        }
    }

    .file_link {
        color: #3b77e7;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 10px;
    }
}
</style>
